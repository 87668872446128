<template>

<div>

  <care-plan :carePlanSection="1"/>

  <care-plan-items :carePlanSection="1" />

</div>

</template>

<script>

import CarePlan from '@/components/CarePlan';
import CarePlanItems from '@/components/CarePlanItems';

export default {
  name: 'CarePlansList',
  components: {
    CarePlanItems,
    CarePlan,
  },
};
</script>

<style scoped>

</style>
